import { Button } from '@/components/ui/button'
import { Icon } from '@/components/ui/icon'
import { ContactForm } from '@/components/marketing/contact-form'

function Hero() {
  return (
    <div className="flex justify-center">
      <div className="mx-auto flex max-w-[70rem] flex-col lg:max-w-[80%] lg:flex-row">
        <div className="mx-auto max-w-7xl px-4 pb-16 pt-8 lg:px-8 lg:pt-32">
          <h1 className="font-display mx-auto max-w-4xl text-4xl font-medium tracking-tight text-slate-900 lg:text-5xl">
            Joyst Homeownership Platform - Secure your future!
          </h1>
          <p className="mx-auto mt-6 max-w-2xl text-lg tracking-tight text-slate-700">
            Joyst is the industry's first comprehensive homeownership platform,
            providing homeowners with an intuitive guide through the key stages
            of their ownership journey, enhancing both financial value and
            personal enjoyment.
          </p>
          <div className="mt-10 flex justify-start gap-x-6">
            <a href="#contact-us">
              <Button variant="brand">
                <span>Learn More</span>
              </Button>
            </a>
          </div>
        </div>
        <img
          src="https://imagedelivery.net/NOEWMzYUjU_jXiPkuzWWsw/cd52c1d0-fe5b-4066-71e8-e4434f9e2200/public"
          alt="Joyst dashboard"
          className="h-auto w-full object-contain lg:w-2/3"
        />
      </div>
    </div>
  )
}

export default function Home() {
  return (
    <div>
      <Hero />

      <div
        id="what-is-joyst"
        className="flex justify-center bg-brand-600 px-2 py-16"
      >
        <div className="grid max-w-[80rem] grid-cols-2 gap-8 lg:px-0">
          <div className="col-span-2 lg:col-span-1">
            <iframe
              src="https://customer-7bfj8l1u2uso9wzo.cloudflarestream.com/2413d7560137bfb37fd726873c69862c/iframe?poster=https%3A%2F%2Fcustomer-7bfj8l1u2uso9wzo.cloudflarestream.com%2F2413d7560137bfb37fd726873c69862c%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
              loading="lazy"
              className="aspect-video w-full"
              // style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;"
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
              allowFullScreen={true}
            ></iframe>
          </div>
          <div className="col-span-2 flex flex-col gap-6 lg:col-span-1 lg:max-w-xl">
            <div className="flex h-12 w-12 items-center justify-center rounded-full border-4 border-brand-700 bg-brand-600 text-white">
              <Icon name="joyst-logo" className="h-6 w-6 fill-white" />
            </div>
            <div>
              <h4 className="font-display mt-2 text-xl text-white sm:text-2xl md:text-3xl">
                What is Joyst
              </h4>
              <p className="mt-4 w-full text-lg leading-relaxed tracking-tight text-white">
                They call it the American Dream — owning your own home. It's
                more than just a place to live; it's a lifetime investment, a
                family sanctuary, and a foundation for cherished memories. Homes
                have long been pillars of financial stability, fostering wealth
                that supports retirement, education, and healthcare. But, like
                all investments, there's no guarantee your home will meet future
                needs.
              </p>
            </div>
          </div>
          <div className="col-span-2 flex flex-col gap-4 text-lg tracking-tight text-white">
            <p className="leading-relaxed text-white">
              Introducing the{' '}
              <span className="font-semibold">
                Joyst Homeownership Platform:
              </span>{' '}
              <br />A modern, intuitive app offering essential insights for
              financial growth, home maintenance, and community engagement.
            </p>
            <div className="flex flex-col gap-4">
              <h4 className="font-display text-white">
                At Joyst, we focus on three core elements:
              </h4>
              <ul className="flex list-disc flex-col gap-2 pl-16 font-semibold">
                <li>Financial Insights - Joyst Capital</li>
                <li>Digital Home Management - Joyst Vault</li>
                <li>Relevant Local Information - Joyst Local</li>
              </ul>
            </div>
            <p className="leading-relaxed text-white">
              Unlock your home's full potential with Joyst, where personal
              prosperity and financial security unite. You’ve earned it!
            </p>
          </div>
        </div>
      </div>

      <div id="capital" className="flex justify-center bg-white px-2 py-16">
        <div className="flex flex-col items-center gap-8 md:gap-16 lg:flex-row lg:items-start">
          <div className="flex max-w-xl flex-col">
            <div className="mb-6 flex h-12 w-12 items-center justify-center rounded-full border-4 border-brand-50 bg-brand-100 text-brand-600">
              <Icon name="bar-chart-2" className="h-6 w-6" />
            </div>
            <h4 className="font-display mt-2 text-xl text-gray-900 sm:text-2xl md:text-3xl">
              <span className="font-bold text-brand-700">Maximize</span> Your
              Home Value
            </h4>
            <p className="mt-4 max-w-xl text-xl leading-relaxed text-gray-500">
              Your home isn’t just a place for memories; it’s a key asset for
              securing your financial future. Joyst Capital provides ongoing
              insights into your home’s market value and how to leverage your
              equity based on your goals and risk tolerance. With this
              understanding, you can make informed decisions to protect,
              enhance, or utilize your home to build long-term wealth.
            </p>
          </div>

          <div>
            <img
              src="https://imagedelivery.net/NOEWMzYUjU_jXiPkuzWWsw/a9dcbb1a-5c5b-427a-f876-8a52631f9500/public"
              alt="Joyst Vault"
            />
          </div>
        </div>
      </div>

      <div id="local" className="flex justify-center bg-brand-600 px-2 py-16">
        <div className="flex flex-col items-center gap-8 md:gap-16 lg:flex-row lg:items-start">
          <div>
            <img
              src="https://imagedelivery.net/NOEWMzYUjU_jXiPkuzWWsw/4b00890d-2de3-4dc7-54ab-638bf9fd3d00/public"
              alt="Joyst Vault"
            />
          </div>
          <div className="flex max-w-xl flex-col py-16">
            <div className="mb-6 flex h-12 w-12 items-center justify-center rounded-full border-4 border-brand-700 bg-brand-600 text-white">
              <Icon name="lightning-bolt" className="h-6 w-6" />
            </div>
            <h4 className="font-display mt-2 text-xl text-white sm:text-2xl md:text-3xl">
              Easy home management
            </h4>
            <p className="mt-4 max-w-xl text-xl leading-relaxed text-white">
              Homeownership brings joy but also the challenge of maintenance.
              Balancing family, work, and life while keeping your home in top
              shape can be overwhelming. Joyst Vault offers a secure, intuitive
              digital space to store key records, legal documents, and critical
              home information. With seamless access to your home’s essential
              data, maintaining, repairing, or improving your largest asset
              becomes much easier.
            </p>
          </div>
        </div>
      </div>

      <div id="local" className="flex justify-center bg-white px-2 py-16">
        <div className="flex flex-col items-center gap-8 md:gap-16 lg:flex-row lg:items-start">
          <div className="flex max-w-xl flex-col">
            <div className="mb-6 flex h-12 w-12 items-center justify-center rounded-full border-4 border-brand-50 bg-brand-100 text-brand-600">
              <Icon name="bar-chart-2" className="h-6 w-6" />
            </div>
            <h4 className="font-display mt-2 text-xl text-gray-900 sm:text-2xl md:text-3xl">
              Community Insight
            </h4>
            <p className="mt-4 max-w-xl text-xl leading-relaxed text-gray-500">
              The phrase "Location, Location, Location" underscores the critical
              role of a property’s location in determining its value and appeal.
              Joyst Local provides timely, accurate, and easily digestible
              updates on neighborhood trends, market conditions, and community
              activities, all of which influence your home’s value and your
              overall homeowner experience.
            </p>
          </div>

          <div>
            <img
              src="https://imagedelivery.net/NOEWMzYUjU_jXiPkuzWWsw/b38b8bd6-ad21-4866-5517-281dfbc43f00/public"
              alt="Joyst Vault"
              width="681"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <p className="mt-4 max-w-xl text-center text-xl leading-relaxed text-gray-500">
          Joyst is currently available only in select states and through
          specific financial institutions or real estate brokerages. Discover
          more about Joyst and its availability in your community.
        </p>
      </div>

      <div
        id="contact-us"
        className="mt-2 flex flex-col items-center justify-center px-4 sm:mb-12 sm:mt-8 lg:items-start"
      >
        <span className="text-md font-bold text-brand-600">Contact us</span>
        <h4 className="font-display mt-2 font-bold tracking-tight sm:text-2xl md:text-3xl">
          Learn more about Joyst
        </h4>
        <p className="mt-4 max-w-2xl text-center text-xl leading-relaxed text-slate-900/50">
          Start accessing advanced consumer and property insights, with Joyst!
        </p>
        <ContactForm />
      </div>
    </div>
  )
}
